import {
  FaGithubSquare,
  FaLinkedin,
  FaPhoneSquare,
  FaMailBulk,
} from "react-icons/fa";

import "./socialMedial.css";

export const SocialMedial = () => {
  const handleEmailClick = () => {
    const recipient = "cyrisenahoun@gmail.com";
    const subject = "Contact through my portfolio";
    const body = "";

    // Construct the mailto URL
    const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open the default email client
    window.location.href = mailtoUrl;
  };

  const handlePhoneClick = () => {
    const phoneNumber = "+447943049514";

    const telUrl = `tel:${phoneNumber}`;

    window.location.href = telUrl;
  };

  return (
    <div className="social-medial">
      <ul>
        <li>
          <a
            href="https://github.com/Cyrille09"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithubSquare size={30} style={{ color: "white" }} />
          </a>
        </li>
        <li>
          <a
            href=""
            onClick={handleEmailClick}
            target="_blank"
            rel="noreferrer"
          >
            <FaMailBulk size={30} style={{ color: "white" }} />
          </a>
        </li>

        <li>
          <a
            href="https://www.linkedin.com/in/cyrille-hounvio-b76000115/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin size={30} style={{ color: "white" }} />
          </a>
        </li>

        <li>
          <a
            href=""
            onClick={handlePhoneClick}
            target="_blank"
            rel="noreferrer"
          >
            <FaPhoneSquare size={30} style={{ color: "white" }} />
          </a>
        </li>
      </ul>
    </div>
  );
};
