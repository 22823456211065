import React, { useState } from "react";
import { FaMailBulk } from "react-icons/fa";
import { Formik, Form } from "formik";
import { FiSend } from "react-icons/fi";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { SocialMedial } from "./components/social-medial/SocialMedial";
import { Input } from "../../components/fields/input";
import { Textarea } from "../../components/fields/texarea";
import { GlobalButton } from "../../components/button";
import { sendMessageForm } from "../../components/formValidation/formValidation";

import "./contact.scss";

export default function ContactPage() {
  const [show, setShow] = useState(false);

  const sendMessage = async (values) => {
    try {
      setShow(true);
      await axios.post(
        "https://node.lawyerexpert.com/api/contacts/my-portfolio",
        values
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  return (
    <div className="contact-main">
      <div className="container">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>Thank you for contacting me!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="global-header">
          <h1 className="global-title">Contact Me</h1>
          <h5 className="global-sub-title">Lets Keeps in Touch</h5>
          <div className="global-line">
            <span></span>
          </div>
        </div>
        <div className="contact-section">
          <div className="contact-row row">
            <div className="contact-details col-lg-6">
              <div className="contact-in-touch">
                <h2>
                  Get In Touch{" "}
                  <span className="contact-email">
                    <FaMailBulk />
                  </span>{" "}
                  <span className="contact-vl"></span>
                </h2>
              </div>
              <div className="contact-social-media">
                <SocialMedial />
              </div>
              <div className="contact-image">
                <p>Send your email here!</p>
                <img
                  src="/images/contact.png"
                  alt="contact"
                  className="contact-img"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-list">
                <Formik
                  form
                  validationSchema={sendMessageForm}
                  initialValues={{ name: "", email: "", message: "" }}
                  onSubmit={sendMessage}
                >
                  {({ handleChange, handleBlur, values, errors }) => (
                    <Form>
                      <div>
                        <div>
                          <div className="contact-list-each">
                            <Input
                              name="name"
                              id="name"
                              type="text"
                              placeholder="Name"
                              label="Name"
                              classNameLabel="contact-label"
                              onChange={handleChange("name")}
                              value={values.name}
                              onBlur={handleBlur("name")}
                              autoCapitalize="none"
                              error={errors.name}
                            />
                          </div>
                          <div className="contact-list-each">
                            <Input
                              name="email"
                              id="email"
                              type="text"
                              placeholder="Email"
                              label="Email"
                              classNameLabel="contact-label"
                              onChange={handleChange("email")}
                              value={values.name}
                              onBlur={handleBlur("email")}
                              autoCapitalize="none"
                              error={errors.email}
                            />
                          </div>

                          <div className="contact-list-each">
                            <Textarea
                              name="message"
                              id="message"
                              placeholder="Enter Message"
                              label="Message"
                              classNameLabel="contact-label"
                              rows={5}
                            />
                          </div>
                          <div className="contact-button">
                            <GlobalButton
                              format={"secondary"}
                              small
                              rightIcon={<FiSend />}
                              type="submit"
                            >
                              Send
                            </GlobalButton>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
