import "./footer.css";
const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div className="footer-main">
      <footer className="container">
        <p>Copyright © Cyrille Hounvio {currentYear}</p>
      </footer>
    </div>
  );
};

export default Footer;
