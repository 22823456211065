import React from "react";
import { useField } from "formik";
import classnames from "classnames";

import styles from "./fields.module.scss";

export function Input({
  type,
  name = "",
  id,
  placeholder,
  label = "",
  size = "",
  disabled,
  className = null,
  min,
  required,
  max,
  onBlur,
  autoCapitalize,
  onChange,
  autoCorrect,
  classNameLabel,
  value,
  style,
  onChangeCapture,
}) {
  // return field name for an <input />
  const [field, meta] = useField(name);
  const isInvalid = meta.error && meta.touched;

  return (
    <>
      {" "}
      {/* className={classnames(styles.field, className)} */}
      {label && (
        <label
          htmlFor={id || name}
          className={classnames(styles.label, classNameLabel)}
        >
          {label} {}
          {required}
        </label>
      )}
      <input
        style={style}
        {...field}
        type={type}
        id={id}
        name={name}
        className={classnames(
          "form-control",
          size,
          isInvalid && styles.isInvalid
        )}
        placeholder={placeholder}
        disabled={disabled}
        min={min}
        max={max}
        onBlur={onBlur}
        autoCapitalize={autoCapitalize}
        onChange={onChange}
        autoCorrect={autoCorrect}
        //value={value}
        onChangeCapture={onChangeCapture}
      />
      {isInvalid && <p className={styles.invalidFeedback}>{meta.error}</p>}
    </>
  );
}
