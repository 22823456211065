export const ProjectData = [
  {
    id: 1,
    targetImage: "/images/projects/ambie-admin-dashboard.png",
    tag: "cms",
    name: "Ambie (Admin Portal)",
    textMessage:
      "Ambie is not a one-size-fits-all product; we’re a unique blend of human expertise and smart technology that delivers a personalised music solution for each of our customers, with zero hassle. Ambie helps 2,000+ hospitality businesses take control of their background music, creating brand consistency and positive guest experiences which drive sales.",
    view: "View CMS Website",
    link: "https://www.dashboard.ambie.fm",
    screens: [
      {
        image: "/images/projects/ambie-admin-login.png",
      },
      {
        image: "/images/projects/ambie-admin-dashboard.png",
      },
      {
        image: "/images/projects/ambie-admin-locations.png",
      },
      {
        image: "/images/projects/ambie-admin-playlists.png",
      },
      {
        image: "/images/projects/ambie-admin-users.png",
      },
      {
        image: "/images/projects/ambie-admin-schedules.png",
      },
      {
        image: "/images/projects/ambie-admin-edit-user.png",
      },
      {
        image: "/images/projects/ambie-admin-edit-playlist.png",
      },
      {
        image: "/images/projects/ambie-admin-add-brief.png",
      },
      {
        image: "/images/projects/ambie-admin-feedback.png",
      },
    ],
  },
  {
    id: 2,
    targetImage: "/images/projects/ambie.png",
    tag: "website",
    name: "Ambie",
    textMessage:
      "Ambie is not a one-size-fits-all product; we’re a unique blend of human expertise and smart technology that delivers a personalised music solution for each of our customers, with zero hassle. Ambie helps 2,000+ hospitality businesses take control of their background music, creating brand consistency and positive guest experiences which drive sales.",
    view: "View Website",
    link: "https://www.ambie.fm/",
    screens: [
      {
        image: "/images/projects/ambie.png",
      },
      {
        image: "/images/projects/ambie-customer-history.png",
      },
      {
        image: "/images/projects/ambie-contact.png",
      },
      {
        image: "/images/projects/ambie-footer.png",
      },
    ],
  },
  {
    id: 3,
    targetImage: "/images/projects/ambie-mobile-locations-specific.jpg",
    tag: "mobile",
    name: "My Ambie App",
    textMessage:
      "Ambie is not a one-size-fits-all product; we’re a unique blend of human expertise and smart technology that delivers a personalised music solution for each of our customers, with zero hassle. Ambie helps 2,000+ hospitality businesses take control of their background music, creating brand consistency and positive guest experiences which drive sales.",
    view: "Scan QR reader code",
    link: "https://www.dashboard.ambie.fm",
    screens: [
      {
        image: "/images/projects/ambie-mobile-login.jpg",
      },
      {
        image: "/images/projects/ambie-mobile-locations.jpg",
      },
      {
        image: "/images/projects/ambie-mobile-curation.jpg",
      },
      {
        image: "/images/projects/ambie-mobile-dropdown.jpg",
      },
      {
        image: "/images/projects/ambie-mobile-intercom.jpg",
      },
      {
        image: "/images/projects/ambie-mobile-locations-specific.jpg",
      },

      {
        image: "/images/projects/ambie-mobile-now-playing.jpg",
      },
      {
        image: "/images/projects/ambie-mobile-now-playing-volume.jpg",
      },
      {
        image: "/images/projects/ambie-mobile-requests.jpg",
      },
      {
        image: "/images/projects/ambie-mobile-schedules.jpg",
      },

      {
        image: "/images/projects/ambie-mobile-sampler-playlists.jpg",
      },
    ],
    qrCode: true,
    qrCodeMessage: "Get Ambie mobile app",
  },

  {
    id: 4,
    targetImage: "/images/projects/ambie-hub-playlists.png",
    tag: "cms",
    name: "Ambie (Hub)",
    textMessage:
      "Ambie is not a one-size-fits-all product; we’re a unique blend of human expertise and smart technology that delivers a personalised music solution for each of our customers, with zero hassle. Ambie helps 2,000+ hospitality businesses take control of their background music, creating brand consistency and positive guest experiences which drive sales.",
    view: "View CMS Website",
    link: "https://www.admin.ambie.fm/",
    screens: [
      {
        image: "/images/projects/ambie-hub-login.png",
      },
      {
        image: "/images/projects/ambie-hub-locations.png",
      },
      {
        image: "/images/projects/ambie-hub-playlists.png",
      },
      {
        image: "/images/projects/ambie-hub-schedules.png",
      },
      {
        image: "/images/projects/ambie-hub-insights.png",
      },
      {
        image: "/images/projects/ambie-hub-locations-areas.png",
      },
      {
        image: "/images/projects/ambie-hub-qr-code.png",
      },
    ],
  },
  {
    id: 5,
    targetImage: "/images/projects/lawyerexpert.png",
    tag: "website",
    name: "Lawyer Expert",
    textMessage:
      "LawyerExpert provide easy access to the right documents and legal services for your business or personal goals. We are a team of entrepreneurs and professionals dedicated to creating a more inclusive and fair society, where legal services are accessible on demand to everyone from a sole trader to corporate executive.",
    view: "View Website",
    link: "https://www.lawyerexpert.com/",
    screens: [
      {
        image: "/images/projects/lawyerexpert.png",
      },
      {
        image: "/images/projects/lawyerexpert-who-we-are.png",
      },
      {
        image: "/images/projects/lawyerexpert-document-banks.png",
      },
      {
        image: "/images/projects/lawyerexpert-pricing.png",
      },
      {
        image: "/images/projects/lawyerexpert-footer.png",
      },
    ],
  },

  {
    id: 6,
    targetImage: "/images/projects/doctaly.png",
    tag: "website",
    name: "Doctaly",
    textMessage: "Staff app message",
    view: "View Website",
    link: "https://doctalyassist.com/",
    screens: [
      {
        image: "/images/projects/doctaly.png",
      },
      {
        image: "/images/projects/doctaly-term.png",
      },
      {
        image: "/images/projects/doctaly-how-it-work.png",
      },
      {
        image: "/images/projects/doctaly-footer.png",
      },
    ],
  },
  {
    id: 7,
    targetImage: "/images/projects/oregonengineeringspecialists.png",
    tag: "website",
    name: "Oregon Engineering Specialists",
    textMessage:
      "At Oregon Engineering Specialists, we specialize in connecting talent with opportunity. As a dedicated engineering recruitment company, we’re committed to sourcing and placing highly skilled professionals in the fields of mechanical and electrical fitting, maintenance engineering, and pipework fitting.",
    view: "View Website",
    link: "https://oregonengineeringspecialists.com/",
    screens: [
      {
        image: "/images/projects/oregonengineeringspecialists.png",
      },
      {
        image: "/images/projects/oregonengineeringspecialists-about-us.png",
      },
      {
        image: "/images/projects/oregonengineeringspecialists-contact.png",
      },
      {
        image: "/images/projects/oregonengineeringspecialists-footer.png",
      },
    ],
  },
  {
    id: 8,
    targetImage: "/images/projects/elizabethambekgeneralstores.png",
    tag: "website",
    name: "Elizabeth Ambek General Stores",
    textMessage:
      "Elizabeth Ambek, who is in the pharmaceutical and cosmetic field, has a diploma in cosmetic science. She has an extensive professional background that has enabled her to successfully launch the business and go from strength to strength",
    view: "View Website",
    link: "https://elizabethambekgeneralstores.com/",
    screens: [
      {
        image: "/images/projects/elizabethambekgeneralstores.png",
      },
      {
        image: "/images/projects/elizabethambekgeneralstores-about-us.png",
      },
      {
        image: "/images/projects/elizabethambekgeneralstores-shop.png",
      },
      {
        image: "/images/projects/elizabethambekgeneralstores-contact.png",
      },
      {
        image: "/images/projects/elizabethambekgeneralstores-footer.png",
      },
    ],
  },
];
