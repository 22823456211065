//import { NavBar } from "./components/Navbar";
import HomePage from "./pages/home/Home";
import AboutPage from "./pages/about/About";
import ServicesPage from "./pages/services/Services";
import ProjectsPage from "./pages/projects/Projects";
import ContactPage from "./pages/contact/Contact";
import ResumePage from "./pages/resume/Resume";
import BackToTop from "./components/backToTop/BackToTop";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <>
      <Navbar />
      <div>
        <div id="homePage">
          <HomePage />
        </div>
        <div id="aboutPage">
          <AboutPage />
        </div>
        {/* <div id="servicesPage">
          <ServicesPage />
        </div> */}
        <div id="projectsPage">
          <ProjectsPage />
        </div>
        <div id="resumePage">
          <ResumePage />
        </div>
        <div id="contactPage">
          <ContactPage />
        </div>
        <BackToTop />
      </div>
      <Footer />
    </>
  );
}

export default App;
