import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
import logo from "../../cy_logo.png";

import "./Navbar.css";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const closeMenu = () => setClick(false);

  return (
    <div className="header">
      <nav className="navbar">
        <div className="navbar-logo">
          <Link
            activeClass="active"
            to="homePage"
            spy={true}
            smooth={true}
            offset={-100}
            duration={100}
            onClick={closeMenu}
          >
            <img src={logo} alt="logo" className="logo" />
          </Link>
        </div>

        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={30} style={{ color: "#ffffff" }} />
          ) : (
            <FaBars size={30} style={{ color: "#ffffff" }} />
          )}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              activeClass="active"
              to="homePage"
              spy={true}
              smooth={true}
              offset={-100}
              duration={100}
              onClick={closeMenu}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              activeClass="active"
              to="aboutPage"
              spy={true}
              smooth={true}
              offset={-100}
              duration={100}
              onClick={closeMenu}
            >
              About
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              activeClass="active"
              to="servicesPage"
              spy={true}
              smooth={true}
              offset={-100}
              duration={100}
              onClick={closeMenu}
            >
              Services
            </Link>
          </li> */}

          <li className="nav-item">
            <Link
              activeClass="active"
              to="projectsPage"
              spy={true}
              smooth={true}
              offset={-100}
              duration={100}
              onClick={closeMenu}
            >
              Projects
            </Link>
          </li>
          <li className="nav-item">
            <Link
              activeClass="active"
              to="resumePage"
              spy={true}
              smooth={true}
              offset={-100}
              duration={100}
              onClick={closeMenu}
            >
              Resume
            </Link>
          </li>
          <li className="nav-item">
            <Link
              activeClass="active"
              to="contactPage"
              spy={true}
              smooth={true}
              offset={-100}
              duration={100}
              onClick={closeMenu}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
