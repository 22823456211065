import * as yup from "yup";

/**
 * message form validation
 */
export const sendMessageForm = yup.object().shape({
  name: yup.string().required("Name is required"),
  message: yup.string().required("Message is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});
