import Modal from "react-bootstrap/Modal";
import classNames from "classnames";

export const GlobalModal = ({
  size,
  backdrop = true,
  centered = true,
  scrollable = false,
  fullscreen,
  title,
  show,
  handleClose,
  className,
  children,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size={size}
        backdrop={backdrop}
        centered={centered}
        fullscreen={fullscreen}
        className={classNames(
          scrollable ? "modal-dialog-scrollable" : "",
          className
        )}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {children}
      </Modal>
    </>
  );
};
