import {
  FaGithubSquare,
  FaLinkedin,
  FaPhoneSquare,
  FaMailBulk,
} from "react-icons/fa";
import "./socialMedial.css";

export const SocialMedial = () => {
  const handleEmailClick = () => {
    const recipient = "cyrisenahoun@gmail.com";
    const subject = "Contact through my portfolio";
    const body = "";

    const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoUrl;
  };

  const handlePhoneClick = () => {
    const phoneNumber = "+447943049514";

    const telUrl = `tel:${phoneNumber}`;

    window.location.href = telUrl;
  };
  return (
    <div className="contact-social-medial">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/senamitech/?modal=admin_todo_tour"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithubSquare size={20} style={{ color: "white" }} />
          </a>
        </li>
        <li>
          <a
            onClick={handleEmailClick}
            href=""
            target="_blank"
            rel="noreferrer"
          >
            <FaMailBulk size={20} style={{ color: "white" }} />
          </a>
        </li>

        <li>
          <a
            href="https://www.linkedin.com/in/cyrille-hounvio-b76000115/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin size={20} style={{ color: "white" }} />
          </a>
        </li>

        <li>
          <a
            onClick={handlePhoneClick}
            href=""
            target="_blank"
            rel="noreferrer"
          >
            <FaPhoneSquare size={20} style={{ color: "white" }} />
          </a>
        </li>
      </ul>
    </div>
  );
};
