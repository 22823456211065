export const EducationData = [
  {
    location: "University of East London",
    title: "MSc (Master) Data Science",
    year: "2017 – 2018",
  },
  {
    location: "University of East London",
    title: "BSc (Hons) Computer Science",
    year: "2014 – 2017",
  },
  {
    location: "South Essex College, Southend-On-Sea",
    title: "ICT Professional Competence level 2 & 3 and Cisco (CCNA)",
    year: "2011 – 2013",
  },
];

export const EmploymentHistoryData = [
  {
    company: "Software Engineer, Ambie, UK",
    year: "April 2022 – Present",
    description: `Ambie helps 2,000+ hospitality businesses to take control of their background music, creating brand consistency and positive guest experiences which drive sales.
    <ul>
    <li>Built and maintained 100% of backend services using <b>Node.js (Express and Nest)</b> and integrate with both <b>MongoDB</b> and <b>SQL (MySQL and PostgreSQL)</b> database.</li>
    <li>Implemented and maintained continuous integration and deployment pipelines with <b>AWS Services</b> and <b>CircleCi</b>.</li>
    <li>Work with the UX team to design and implement a user-friendly content management system with <b>React.js and Next.js</b>.</li>
    <li>Contributed to the in-house UI library to create a reusable component that save hours of developments.</li>
    <li>Developed a mobile app for clients with <b>React Native</b>.</li>
    <li>Implemented real-time updates using <b>WebSockets</b> and integrated with the back-end APIs.</li>
    <li>Developed multiple automated test suites to validate API, back-end and Front-end components.</li>
    <li>Led and managed the development team, providing mentorship and guidance to ensure project success.</li>
    <li>Collaborated with third-party organizations to identify and implement optimal solutions for the company.</li>
    <li>Engaged with clients, actively listening to their feedback to improve our systems and services.</li>
    <li>Collaborated closely with cross-functional teams to design and implement a user-friendly content management system with <b>React.js and Next.js</b>.</li>
    <li>Facilitated communication and cooperation between development, design, and testing teams to ensure the successful implementation of real-time updates using <b>WebSockets</b>.</li>
    <li>Took the initiative to develop and implement multiple automated test suites to validate API, backend, and frontend components, improving overall development efficiency and code quality.</li>
    </ul>`,
  },
  {
    company: "Software Developer, Doctaly, UK",
    year: "Feb 2021 – Feb 2022",
    description: `Doctaly Assist is a highly flexible Remote Patient Monitoring platform developed in collaboration with the NHS, patient groups and leading UK academic institutions, currently covering over two million patients in South East London with a range of Long Term Conditions.
    <ul>
    <li>Built and maintained 100% of backend services using <b>Node.js (Express)</b></li>
    <li>Worked with the back-end team to implement RESTful APIs and integrate with both <b>MongoDB</b> and <b>PostgreSQL</b> database.</li>
    <li>Collaborated with cross-functional teams, including designers, product managers, and QA engineers.</li>
    <li>Implemented new features into an existent project that was built with <b>React.js and Next.js</b></li>
    <li>Took initiative to implement new features into an existing project built with <b>React.js</b>, demonstrating proactive problem-solving and a commitment to continuous improvement.</li>
    <li>Collaborated closely with the back-end team to implement <b>RESTful APIs</b> and integrate with both <b>MongoDB and PostgreSQL</b> databases, fostering effective teamwork and communication to deliver robust solutions.</li>
    <li>Facilitated collaboration across cross-functional teams, including designers, product managers, and QA engineers, to ensure alignment with project goals and user requirements.</li>
    </ul>`,
  },
  {
    company: "Software Developer, Johnson Care Group, UK",
    year: "Mar 2016 – Feb 2021",
    description: `Johnson Care Group provide residents with a comfortable, safe, and supportive environment where they can receive the care and assistance, they need to maintain their independence and quality of life.
    <ul>
    <li>Developed and maintained<b> Digital care planning</b> for every patient within the organisation with <b>React.js</b> and <b>MySQL</b> for the database.</li>
    <li>Built and maintained <b>Content Management System (CMS)</b> with <b>Next.js</b> and <b>Redux</b>.</li>
    <li>Implemented and maintained<b> Digital daily care records</b> to register the patient daily activity such as observation, body map, repositioning, food nutritional, personal hygiene and toileting.</li>
    <li>Mentored junior developers and provided technical guidance to the team.</li>
    <li>Worked with the back-end team to implement RESTful APIs with <b>Node.js</b> and integrate with databases.</li>
    <li>Developed and maintained a mobile app with <b>React Native</b> that is used for viewing Rotas, booking annual leaves, requesting a specific day off, etc.…</li>
    <li>I took charge of developing and maintaining a mobile application with <b>React Native</b>, empowering users to manage their schedules and requests seamlessly.</li>
    <li>My leadership and collaboration skills were instrumental in driving project success and ensuring effective teamwork across departments.</li>
    </ul>`,
  },
];

export const ProgrammingSkillsData = [
  {
    programming: "React JS",
    percentage: 95,
  },
  {
    programming: "React Native",
    percentage: 90,
  },
  {
    programming: "Next JS",
    percentage: 85,
  },
  {
    programming: "JavaScript",
    percentage: 80,
  },
  {
    programming: "Typescript",
    percentage: 90,
  },
  {
    programming: "MongoDB",
    percentage: 84,
  },
  {
    programming: "SQL",
    percentage: 95,
  },
  {
    programming: "Node JS (Express, Nest)",
    percentage: 98,
  },
  {
    programming: "AWS",
    percentage: 75,
  },
  {
    programming: "Linux",
    percentage: 45,
  },
];

export const ProjectsData = [
  {
    project: "Ambie (UK)",
    title: "MSc (Master) Data Science",
    content: "Message here ...",
    year: "2017 – 2018",
  },
  {
    project: "Doctaly (UK)",
    title: "BSc (Hons) Computer Science",
    content: "Message here ...",
    year: "2014 – 2017",
  },
  {
    project: "Jonshon Care Group (UK)",
    title: "ICT Professional Competence level 2 & 3 and Cisco (CCNA)",
    content: "Message here ...",
    year: "2011 – 2013",
  },
];

export const interestsData = [
  {
    title: "Technology",
    content:
      "Stay up to date with the latest advancements in technology, and I enjoy building websites and experimenting with programming languages in my free time.",
  },
  {
    title: "Football",
    content:
      "I am passionate about football and I play in a local league as well as following both leagues and international competitions.",
  },
  {
    title: "Play Drum",
    content: "Play the drums, football and enjoy listening to music.",
  },
];
