import React from "react";
import { useField } from "formik";
import classnames from "classnames";

import styles from "./fields.module.scss";

export function Textarea({
  name = "",
  id,
  placeholder,
  label,
  disabled,
  classNameLabel,
  rows = 2,
  className = null,
}) {
  const [field, meta] = useField(name);
  const isInvalid = meta.error && meta.touched;

  return (
    <div className={classnames(styles.field, className)}>
      {label && (
        <label
          htmlFor={id || name}
          className={classnames(styles.label, classNameLabel)}
        >
          {label}
        </label>
      )}
      <textarea
        {...field}
        id={id}
        name={name}
        className={classnames(
          styles.input,
          styles.textarea,
          isInvalid && styles.invalid
        )}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
      ></textarea>
      {isInvalid && <p className={styles.error}>{meta.error}</p>}
    </div>
  );
}
