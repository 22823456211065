import React, { useState } from "react";
import { FaDownload, FaHeadphonesAlt } from "react-icons/fa";
import { BsInboxesFill } from "react-icons/bs";
import { VscTasklist } from "react-icons/vsc";
import { GlobalButton } from "../../components/button";

import "./about.css";

export default function AboutPage() {
  const currentDate = new Date();
  const startDate = new Date(2016, 0, 1);

  const diffTime = Math.abs(currentDate - startDate);
  const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365.25));

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleDownload = () => {
    const pdfUrl = "/resume/CyrilleHounvioCV.pdf";

    // Create an anchor element
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = "Cyrille Hounvio CV.pdf";

    // Trigger the download
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
  };

  return (
    <div className="about-main">
      <div className="container">
        <div className="global-header">
          <h1 className="global-title">About Me</h1>
          <h5 className="global-sub-title">Why Choose Me?</h5>
          <div className="global-line-center">
            <div className="global-line">
              <span></span>
            </div>
          </div>
        </div>
        <div className="about-section">
          <div className="row">
            <div className="about-picture col-lg-6">
              <img
                src="/images/cyrille4.png"
                alt="logo"
                className="about-section-image"
              />
            </div>
            <div className="about-content col-lg-6">
              <div className="about-card-row">
                <div className="about-card">
                  <div className="about-icon">
                    <VscTasklist />
                  </div>
                  <div className="about-card-subject">
                    <p>Experince</p>
                  </div>
                  <div className="about-card-task">
                    <p>{`${diffYears} + Years`}</p>
                  </div>
                </div>
                <div className="about-card">
                  <div className="about-icon">
                    <BsInboxesFill />
                  </div>
                  <div className="about-card-subject">
                    <p>Completed</p>
                  </div>
                  <div className="about-card-task">
                    <p>27 + Projects</p>
                  </div>
                </div>
                <div className="about-card">
                  <div className="about-icon">
                    <FaHeadphonesAlt />
                  </div>
                  <div className="about-card-subject">
                    <p>Support</p>
                  </div>
                  <div className="about-card-task">
                    <p>Online 24/7</p>
                  </div>
                </div>
              </div>

              <div className="about-description">
                <p>
                  I am a highly motivated and hardworking individual with a keen
                  interest in continuing to pursue a career in software
                  engineering. Throughout my tenure as a Software Engineer, I
                  have dedicated myself to crafting scalable and well-documented
                  code. I thrive in collaborative team environments, yet also
                  excel at independently managing projects.
                </p>

                {!showMore && (
                  <p>
                    I am a full-stack developer who has developed numerous
                    projects for various companies utilising React.js and
                    Next.js for the frontend, and Node.js (Express and Nest.js)
                    for the backend.{" "}
                    <span
                      className="about-view-content"
                      onClick={toggleShowMore}
                    >
                      Read More...
                    </span>
                  </p>
                )}
                {showMore && (
                  <>
                    <p>
                      I am a full-stack developer who has developed numerous
                      projects for various companies utilising React.js and
                      Next.js for the frontend, and Node.js (Express and
                      Nest.js) for the backend. Additionally, I have constructed
                      a variety of mobile applications with React Native and
                      React Native expo. With years of experience, I have
                      consistently delivered satisfactory results to my clients.
                      Furthermore, I hold a BSc (Hons) degree in Computer
                      Science and an MSc in Data Science.
                    </p>
                    <p>
                      In addition to my technical skills, I possess strong
                      leadership and collaboration abilities. I have led
                      development teams in delivering successful projects,
                      effectively collaborated with cross-functional teams to
                      ensure alignment with project goals, and mentored junior
                      developers to enhance their skills and knowledge. My
                      excellent communication and interpersonal skills enable me
                      to work effectively with stakeholders, clients, and team
                      members, fostering a positive and productive work
                      environment.
                    </p>

                    <p>
                      I have hands-on experience with AWS services such as EC2,
                      RDS, ECR, ECS, IAM, Route 53, S3, Lambda, API Gateway,
                      SQS, ElastiCache, IoT, SNS,Load Balancing, Amplify,
                      CloudFront, CloudFormation and more, effectively deploying
                      and managing cloud infrastructure to support scalable and
                      reliable applications.{" "}
                      <span
                        className="about-view-content"
                        onClick={toggleShowMore}
                      >
                        Hide...
                      </span>
                    </p>
                  </>
                )}
              </div>
              <div className="about-button">
                <GlobalButton
                  format={"secondary"}
                  rightIcon={<FaDownload />}
                  onClick={handleDownload}
                >
                  Download CV
                </GlobalButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
