import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Carousel } from "react-bootstrap";
import { ProjectData } from "./data";
import { GlobalButton } from "../../components/button";
import { GlobalModal } from "../../components/modal";
import "./projects.css";

export default function ProjectsPage() {
  const [tag, setTag] = useState("website");
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({});
  const [modal, setModal] = useState(false);
  const [deviceType, setDeviceType] = useState("desktop");
  const [modalQrCode, setModalQrCode] = useState(false);
  const [opSystem, setOpSystem] = useState("ios");

  useEffect(() => {
    tag === "all"
      ? setProjects(ProjectData)
      : setProjects(ProjectData.filter((data) => data.tag === tag));

    const handleResize = () => {
      if (window.innerWidth < 576) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 992) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    // Initial check
    handleResize();

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tag, project, deviceType]);

  const TagButton = ({ name, handleSetTag, tagActive, tagName }) => {
    return (
      <GlobalButton
        xSmall
        className={`tag ${tagActive ? "tag-active" : null}`}
        onClick={() => handleSetTag(tagName)}
      >
        {name.toUpperCase()}
      </GlobalButton>
    );
  };
  const getProject = (id) => {
    setProject(ProjectData[id - 1]);
  };

  return (
    <div className="projects-main">
      <div className="container">
        <GlobalModal
          title={(project && project.name) || ""}
          size="lg"
          show={modal}
          handleClose={() => setModal(false)}
        >
          <div className="project-modal">
            <div
              className={
                project.tag === "mobile"
                  ? "project-images-mobile"
                  : "project-images"
              }
            >
              <Carousel>
                {project?.screens?.map((data, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img src={data.image} alt="list" />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
            <div>
              <p>{project?.textMessage}</p>
            </div>
          </div>
        </GlobalModal>

        <GlobalModal
          title={(project && project.qrCodeMessage) || ""}
          size="md"
          show={modalQrCode}
          handleClose={() => setModalQrCode(false)}
        >
          <div className="project-modal">
            <div
              className={
                project.tag === "mobile"
                  ? "project-images-mobile"
                  : "project-images"
              }
            >
              <div className="modal-body full-width side-menu-modal">
                <div>
                  <p>
                    Scan the code below with the mobile camera or with a QR
                    reader app.
                  </p>
                </div>
                <div>
                  <div className="side-menu-modal-tabs side-menu-modal-tab-item-unique">
                    <button
                      className={`side-menu-modal-tab-item ${
                        opSystem === "ios" && "active"
                      }`}
                      onClick={() => setOpSystem("ios")}
                    >
                      iOS
                    </button>
                    <button
                      className={`side-menu-modal-tab-item ${
                        opSystem === "android" && "active"
                      }`}
                      onClick={() => setOpSystem("android")}
                    >
                      Android
                    </button>
                  </div>

                  <div className="mobile-qr-code">
                    {opSystem === "ios" ? (
                      <img src="/images/qr-code/ios.png" alt="ios" />
                    ) : (
                      <img src="/images/qr-code/android.png" alt="android" />
                    )}
                  </div>
                </div>

                <div className="flex justify-content-center align-items-center">
                  <div
                    className="side-menu-modal-logo"
                    onClick={() => {
                      window.open(
                        `https://apps.apple.com/gb/app/my-ambie/id1638901418`
                      );
                    }}
                  >
                    <img
                      src="/images/qr-code/app-stores.png"
                      alt="ios"
                      className="app-stores-logo"
                    />
                  </div>
                  <div
                    className="side-menu-modal-logo"
                    onClick={() => {
                      window.open(
                        `https://play.google.com/store/apps/details?id=com.ambie.ambie`
                      );
                    }}
                  >
                    <img
                      src="/images/qr-code/play-store.png"
                      alt="android"
                      className="play-stores-logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GlobalModal>
        <div className="global-header">
          <h1 className="global-title">Projects</h1>
          <h5 className="global-sub-title">Most recent work</h5>
          <div className="global-line">
            <span></span>
          </div>
        </div>
        <div className="projects-section">
          <div className="tags">
            {/* <TagButton
              name="all"
              tagName="all"
              tagActive={tag === "all" ? true : false}
              handleSetTag={setTag}
            /> */}
            <TagButton
              name="Website"
              tagName="website"
              tagActive={tag === "website" ? true : false}
              handleSetTag={setTag}
            />
            <TagButton
              name="cms"
              tagName="cms"
              tagActive={tag === "cms" ? true : false}
              handleSetTag={setTag}
            />
            <TagButton
              name="mobile app"
              tagName="mobile"
              tagActive={tag === "mobile" ? true : false}
              handleSetTag={setTag}
            />
          </div>
          <div className="project-lists">
            <div className="row">
              {projects?.map((project, index) => {
                return (
                  <div className="col-md-6" key={index}>
                    <div className="project-card">
                      <div className="project-card-picture">
                        <img
                          src={
                            project.targetImage ||
                            "https://via.placeholder.com/150"
                          }
                          alt=""
                          className={
                            project.tag === "mobile"
                              ? "project-mobile-card-img"
                              : "project-card-img"
                          }
                        />
                      </div>
                      <div className="project-card-title">
                        <h3>{project.name}</h3>
                      </div>
                      <div className="project-card-link">
                        <div className="project-card-link-row">
                          <div className="project-card-button-left">
                            {project?.screens?.length && (
                              <GlobalButton
                                xSmall
                                format="gray"
                                rightIcon={<FaAngleRight />}
                                onClick={() => {
                                  setModal(true);
                                  getProject(project.id);
                                }}
                              >
                                View Screens
                              </GlobalButton>
                            )}
                          </div>
                          {project.view && !project.qrCode && (
                            <div className="project-card-button-right">
                              <GlobalButton
                                xSmall
                                format="gray"
                                rightIcon={<FaAngleRight />}
                                href={project.link}
                                target={
                                  deviceType === "desktop" ? "_blank" : ""
                                }
                              >
                                {project.view}
                              </GlobalButton>
                            </div>
                          )}

                          {project.view && project.qrCode && (
                            <GlobalButton
                              xSmall
                              format="gray"
                              rightIcon={<FaAngleRight />}
                              onClick={() => {
                                setModalQrCode(true);
                                getProject(project.id);
                              }}
                            >
                              {project.view}
                            </GlobalButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
