import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import "./backToTop.css";

export default function BackToTop() {
  const [backToTop, setBackToTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTop(true);
      } else {
        setBackToTop(false);
      }
    });
  }, [backToTop]);
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="">
      {backToTop && (
        <button className="backToTop" onClick={scrollUp}>
          <FaArrowUp />
        </button>
      )}
    </div>
  );
}
