import classNames from "classnames";
import { Link } from "react-scroll";

import styles from "./button.module.scss";
export const GlobalButton = ({
  type,
  className,
  onClick,
  disabled,
  leftIcon,
  rightIcon,
  format,
  small,
  xSmall,
  target = null,
  href,
  to,
  children,
}) => {
  if (href && target) {
    return (
      <a
        href={href}
        target={target}
        className={classNames(
          styles.btn,
          styles[format],
          small && styles.small,
          xSmall && styles.xSmall,
          className
        )}
        onClick={onClick}
        rel="noopener noreferrer"
      >
        <span>
          {leftIcon && <span>{leftIcon}</span>} {children}{" "}
          {rightIcon && <span>{rightIcon}</span>}
        </span>
      </a>
    );
  }
  if (href) {
    return (
      <a
        href={href}
        target={target}
        className={classNames(
          styles.btn,
          styles[format],
          small && styles.small,
          xSmall && styles.xSmall,
          className
        )}
        onClick={onClick}
        rel="noopener noreferrer"
      >
        <span>
          {leftIcon && <span>{leftIcon}</span>} {children}{" "}
          {rightIcon && <span>{rightIcon}</span>}
        </span>
      </a>
    );
  }
  if (to) {
    return (
      <Link
        className={classNames(
          styles.btn,
          styles[format],
          small && styles.small,
          xSmall && styles.xSmall,
          className
        )}
        to={to}
      >
        <span>
          {leftIcon && <span>{leftIcon}</span>} {children}{" "}
          {rightIcon && <span>{rightIcon}</span>}
        </span>
      </Link>
    );
  }
  return (
    <button
      className={classNames(
        styles.btn,
        styles[format],
        small && styles.small,
        xSmall && styles.xSmall,
        className
      )}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <span>
        {leftIcon && <span>{leftIcon}</span>} {children}{" "}
        {rightIcon && <span>{rightIcon}</span>}
      </span>
    </button>
  );
};
